@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@layer base {
  html {
    font-family: Montserrat, sans-serif;
    scrollbar-gutter: auto;
  }
  
  body {
    overflow-y: auto;
    margin-right: 0;
  }

  body::-webkit-scrollbar {
    width: 12px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}
